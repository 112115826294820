<script>
import MenuPage from './MenuPage.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
		MenuPage,
	},
	data() {
		return {
			imageBackground: `/img/oficina.jpg`,
		}
	},
	computed: {		
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	methods: {		
		async postLogout() {
			try {
				let logout = await this.authLogout()
                if (logout){
                    this.$nextTick(() => {
                        location.reload()
                    })
                }	
			} catch (error) {
                console.log(error);
			}
		},
		...mapActions({
			authLogout: 'auth/logout'
		})
	},
}
</script>
<template>
	<div class="wrapper">
		
		<!-- Navbar -->
		<!-- <nav class="main-header navbar navbar-expand navbar-white">
			<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" data-widget="pushmenu" href="#" role="button">
						<i class="fas fa-bars"></i></a>
				</li>
			</ul>

			<ul class="navbar-nav ml-auto">

				<li class="nav-item dropdown">
					<a class="nav-link" data-toggle="dropdown" href="#">
						<i class="fa fa-user-circle" aria-hidden="true"></i>
					</a>
					<div class="dropdown-menu dropdown-menu dropdown-menu-right">
						<a href="#" class="dropdown-item text-center">
							Editar cuenta
						</a>
						<div class="dropdown-divider"></div>
						<a href="#" @click="postLogout" class="dropdown-item text-center">
							<i class="fa fa-external-link" aria-hidden="true"></i> Cerrar sesión
						</a>
					</div>

				</li>				
			</ul>
		</nav> -->
		<!-- /.navbar -->

		<!-- Main Sidebar Container -->
		<aside class="main-sidebar elevation-4 sidebar-light-warning bg-danger-bar text-white text-sm">
			<!-- Brand Logo -->
			<router-link :to="{ name: 'dashboard' }" class="text-center">
				<div class="div_logo text-left pt-4">
					<img src="@/assets/img/logo_elektra_blanco.png" alt="logo" class="rounded" style="opacity: .8; width: 80%; ">
				</div>
                <!-- <div class="text-center ml-5" style="position: fixed;">
                    <h4 class="brand-text pt-2 font-weight-light"> Administrador</h4>
                </div>
                <br> -->
			</router-link>

			<!-- Sidebar -->
			<div class="sidebar">

				<MenuPage></MenuPage>

			</div>
			<!-- /.sidebar -->
		</aside>
		<div class="content-wrapper">
			<div class="col-12 pt-5">

				<!-- Main content -->
				<section class="content">
					<div class="container-fluid">
						<a class="btn btn-sm btn-danger btn-menu d-lg-none d-sm-block d-md-block" data-widget="pushmenu" href="#" role="button">
						<i class="fas fa-bars"></i></a>

						<router-view></router-view>
					</div>
				</section>
			</div>
		</div>
	</div>

</template>
<style>
.btn-menu{
	position: absolute;
    left: 20px;
	z-index: 1;
	top: 5px;
}
.wrapper{
	background-image: url('@/assets/img/fondo.png');
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.content-wrapper{
	background-image: url('@/assets/img/fondo_01.jpg');
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
</style>