<script>
import { mapGetters, mapActions } from 'vuex';

import ProfileForm from '@/components/Users/ProfileFormComponent.vue';

export default { 
    components: {
		ProfileForm
	},
	data() {
		return {
		}
	},
	methods:{
		async postLogout() {
			try {
				let logout = await this.authLogout()
                if (logout){
                    this.$nextTick(() => {
                        location.reload()
                    })
                }	
			} catch (error) {
                console.log(error);
			}
		},
		showModalProfile() {
			Event.$emit('event-form-profile', this.authUser.id);
			this.$modal.show('Profileusers');
		},
		...mapActions({
			authLogout: 'auth/logout'
		})
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}

</script>
<template>
	<nav class="mt-2">
		<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
			<li class="nav-item border-white border-bottom ">
				<router-link :to="{ name: 'dashboard' }" active-class="active text-danger" class="nav-link text-light">
					<!-- <i class="nav-icon fas fa-circle"></i> -->
					<p>
						Próximos eventos 
					</p>
				</router-link>
			</li>
			<li class="nav-item border-white border-bottom" v-if="authUser.profiles_id == 1" >
				<router-link :to="{ name: 'users' }" active-class="active text-danger" class="nav-link text-light">
					<!-- <i class="nav-icon fas fa-circle"></i> -->
					<p>
						Usuarios
					</p>
				</router-link>
			</li>
			<li class="nav-item border-white border-bottom">
				<router-link :to="{ name: 'events' }" active-class="active text-danger" class="nav-link text-light">
					<!-- <i class="nav-icon fas fa-circle"></i> -->
					<p>
						Eventos
					</p>
				</router-link>
			</li>
			<li class="nav-item border-white border-bottom">
				<router-link :to="{ name: 'questionnaire' }" active-class="active text-danger" class="nav-link text-light">
					<!-- <i class="nav-icon fas fa-circle"></i> -->
					<p>
						Formularios
					</p>
				</router-link>
			</li>
			<li class="nav-item border-white border-bottom">
				<router-link :to="{ name: 'participants' }" active-class="active text-danger" class="nav-link text-light">
					<!-- <i class="nav-icon fas fa-circle"></i> -->
					<p>
						Ficha de participantes
					</p>
				</router-link>
			</li>
			<li class="nav-item border-white border-bottom">
				<router-link :to="{ name: 'wildcards' }" active-class="active text-danger" class="nav-link text-light">
					<!-- <i class="nav-icon fas fa-circle"></i> -->
					<p>
						Comodines
					</p>
				</router-link>
			</li>
			<li class="nav-item border-white border-bottom">
				<router-link :to="{ name: 'dynamics' }" active-class="active text-danger" class="nav-link text-light">
					<!-- <i class="nav-icon fas fa-circle"></i> -->
					<p>
						Dinámicas
					</p>
				</router-link>
			</li>
			<li class="nav-item border-white border-bottom">
				<button class="nav-link text-light text-left" @click="postLogout" >
					<!-- <i class="nav-icon fas fa-circle"></i> -->
					<p>
						Cerrar sesión
					</p>
				</button>
			</li>
		</ul>
		<div class="row p-2 session_log">
			<div class="col-4">
				<img src="@/assets/img/user.png" class="img-fluid rounded-circle" alt="">
			</div>
			<div class="col-8" style="cursor: pointer; " @click="showModalProfile()">
				<h6 class="mt-1 pt-2">{{authUser.name}}</h6>
				<p class="mt-n2">{{authUser.profile.name}}</p>
			</div>
		</div>
		<ProfileForm/>
	</nav>
</template>
<style>
.session_log{
	bottom: 0px;
    position: absolute;
}
.active{
	background-color: white;
	color: rgba(185, 0, 0, 1);
}
</style>