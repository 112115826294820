<script>
import request from '@/services/request'
import logbooks from '@/services/logbooks'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ModalVue from '../ModalVue.vue'
import { quillEditor } from 'vue-quill-editor'
export default {
    components: {
        quillEditor,
        ModalVue
    },
    data() {
        return {
            pictureUrl: null,
            step: 1,
            eventInfo: null,
            quillConfig: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                },
                placeholder: 'Agregar minuta',
                formats: [
                    'background',
                    'bold',
                    'color',
                    'font',
                    'code',
                    'italic',
                    'link',
                    'size',
                    'strike',
                    'script',
                    'underline',
                    'blockquote',
                    'header',
                    'indent',
                    'list',
                    'align',
                    'direction',
                    'code-block',
                    'formula',

                ]
            },
            current: 1,
            history: [1],
            picture: null,
            categories: [
                {
                    id: 1,
                    label: 'Operación',
                    content: ''
                },
                {
                    id: 2,
                    label: 'Comunicación',
                    content: ''
                },
                {
                    id: 3,
                    label: 'Reconocimiento',
                    content: ''
                },
                {
                    id: 4,
                    label: 'Colaboradores',
                    content: ''
                },
                {
                    id: 5,
                    label: 'Fortalezas',
                    content: ''
                },
                {
                    id: 6,
                    label: 'Agradecimiento',
                    content: ''
                },
            ],
            log_book: null,
            participants: []
        }
    },
    methods: {
        async sendMinuta() {
            this.$refs.modalFinish.open()
            this.$refs.modalFinish.setLoading(true, 'Enviando')
            const formData = new FormData();
            formData.append('picture', this.picture)
            let logs = {
                categories: this.categories
            }
            formData.append('logs', JSON.stringify(logs))
            formData.append('event_id', this.eventInfo.id)
            formData.append('participants', this.participants)

            try {
                let { data } = await logbooks.create(formData)
                this.$set(this, 'log_book', data.log_book)
                this.$refs.modalFinish.setLoading(false)
            } catch (error) {
                console.log(error)
                this.$refs.modalFinish.setLoading(false)
                this.$refs.modalFinish.close()
                this.$refs.modalError.open()
            }

        },
        async getEvent() {
            let id = this.$route.params.id;
            let _endpoint = `/events/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                this.$set(this, 'eventInfo', data)
                return Promise.resolve(data)
            } catch (error) {
                console.log(error)
            }
        },
        onEditorBlur(quill) {
            console.log('editor blur!', quill)
        },
        onEditorFocus(quill) {
            console.log('editor focus!', quill)
        },
        onEditorReady(quill) {
            console.log('editor ready!', quill)
        },
        onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text)
            this.content = html
        },
        setCurrent(id) {
            this.current = id
            this.history.push(id)
        },
        setStep(step) {
            this.step = step
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            this.picture = file
            const reader = new FileReader();
            reader.onload = () => {
                this.pictureUrl = reader.result;
            };
            reader.readAsDataURL(file);
        },
        downloadPdf(id) {
            logbooks.downloadPdf(id)
        }
    },

    async mounted() {
        // this.$refs.modalFinish.open()
        // this.$refs.modalFinish.setLoading(true)
        await this.getEvent()
    }
}
</script>
<template>
    <div class="pb-3">
        <h1>Minuta</h1>
        <div v-if="eventInfo">
            <h4 class="text-secondary">{{ eventInfo.type.name }} - {{ eventInfo.date | dateName }} </h4>
            <div class="mt-3 logbook__content">
                <div v-if="step == 1" class="p-3">
                    <h3>Asistencia</h3>
                    <div class="justify-content-center p-4 table-responsive" style="max-height: 450px;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Asistencia</th>
                                    <th>Nombre Completo</th>
                                    <th>Correo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(participant, index_participant) in eventInfo.participants"
                                    :key="index_participant">
                                    <td>
                                        <input type="checkbox" class="form-control form-control-sm" v-model="participants"
                                            :value="participant.id">
                                    </td>
                                    <td>
                                        {{ participant.name }} {{ participant.last_name }}
                                    </td>
                                    <td>
                                        {{ participant.email }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>                        
                    </div>
                
                    <div class="logbook__alert mt-3" v-if="participants.length == 0">
                        <div class="logbook__alert-icon">
                            <i class="fas fa-exclamation-triangle"></i>
                        </div>
                        <div class="logbook__alert-content">
                            <p class="m-0">Selecciona los participantes que asistieron para continuar</p>
                        </div>
                    </div>
                    <div v-else class="text-center mt-4">
                        <button class="btn btn-warning" @click="setStep(2)">Continuar</button>
                    </div>
                </div>
                <div v-else-if="step == 2" class="p-3">


                    <div class="d-flex justify-content-center">
                        <label>
                            <div class="logbook__picture-wrap">
                                <div v-if="pictureUrl">
                                    <img class="logbook__picture" :src="pictureUrl" alt="Imagen cargada">
                                    <p class="m-0 text-center">Clic aquí para cambiar captura</p>
                                </div>
                                <div v-else class="px-3 text-center">
                                    <div>
                                        <i class="far fa-image logbook__picture-icon"></i>
                                    </div>
                                    <p class="m-0">Clic aquí para cargar captura</p>
                                </div>
                            </div>
                            <input class="d-none" type="file" @change="handleImageUpload">
                        </label>
                    </div>

                    <div class="logbook__alert mt-3" v-if="!picture">
                        <div class="logbook__alert-icon">
                            <i class="fas fa-exclamation-triangle"></i>
                        </div>
                        <div class="logbook__alert-content">
                            <p class="m-0">Toma una captura de la sesión y cárgala para continuar</p>
                        </div>
                    </div>
                    <div v-else class="text-center mt-4">
                        <button class="btn btn-warning" @click="setStep(3)">Continuar</button>
                    </div>
                </div>
                <div v-else-if="step == 3">
                    <div class="logbook__item" v-for="(category, index) in categories" :key="`category-${index}`">
                        <button class="logbook__heading px-3 py-2"
                            @click="history.includes(category.id) ? setCurrent(category.id) : null">{{ category.label }}
                            <i class="fas fa-chevron-right logbook__heading-indicator"
                                :class="{ 'logbook__heading-indicator--open': current == category.id }"></i>
                        </button>
                        <div class="logbook__quill" :class="{ 'logbook__quill--open': current == category.id }">
                            <div class="p-3">

                                <quill-editor class="logbook-editor" ref="myQuillEditor" v-model="category.content"
                                    :options="quillConfig" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                                    @ready="onEditorReady($event)" />
                                <div class="text-center mt-3">
                                    <button class="btn btn-warning mx-2" v-if="current > 1"
                                        @click="setCurrent(category.id - 1)">Volver</button>
                                    <button class="btn btn-warning mx-2" v-if="current < categories.length"
                                        @click="setCurrent(category.id + 1)"
                                        :disabled="!category.content">Continuar</button>
                                    <button class="btn btn-warning mx-2" v-else @click="sendMinuta"
                                        :disabled="!category.content">Enviar</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
        <modal-vue ref="modalFinish">
            <template v-slot:content>
                <div class="p-3">
                    <h3 class="modal__title text-center">Sesión finalizada</h3>
                    <p class="modal__text text-center">
                        Se ha enviado carta de agradecimiento a los participantes y guardado la minuta.
                    </p>
                    <div class="text-center">
                        <button class="btn btn-warning mx-2" v-if="log_book" @click="downloadPdf(log_book.id)">
                            Descargar minuta
                        </button>
                        <router-link :to="{ name: 'dashboard' }" class="btn btn-elektra mx-2">
                            Volver a inicio
                        </router-link>
                    </div>
                </div>
            </template>
        </modal-vue>
        <modal-vue ref="modalError">
            <template v-slot:content>
                <div class="p-3">
                    <h3 class="modal__title text-center">Algo salio mal</h3>
                    <p class="modal__text text-center">
                        Hemos tenido problemas para finalizar la sesión.
                    </p>
                    <div class="text-center">
                        <button @click="$refs.modalError.close" class="btn btn-elektra">
                            Cerrar
                        </button>
                    </div>
                </div>
            </template>
        </modal-vue>
    </div>
</template>
<style lang="scss">
.btn-elektra {
    background-color: #e42313;
    color: #fff;

    &:hover {
        color: #fff;
        background-color: #cd0f08;
    }
}

.modal__text {
    font-size: 1.2rem;
}

.logbook {
    &__picture-wrap {
        border: 3px solid #e42313;
        width: 200px;
        height: 200px;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #f1f1f1;
        transition: 0.3s;

        &:hover {
            background-color: #f7dcd9;
            transform: scale(0.9);
        }
    }

    &__picture {
        width: 100%;

        &-icon {
            font-size: 3rem;
            color: #e42313;
        }
    }
}

.logbook {
    &__content {
        background: #fff;
        border-radius: 1rem;
        box-shadow: 4px 4px 3px rgba(#261962, 0.2);
        overflow: hidden;
    }

    &__heading {
        background-color: #f1f1f1;
        width: 100%;
        display: block;
        text-align: left;
        border: 0 none;
        outline: none !important;
        border-top: 1px solid #e5e4e4;
        position: relative;

        &-indicator {
            color: #e2211c;
            position: absolute;
            right: 0;
            display: inline-block;
            padding: 0 1rem;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.3s;

            &--open {
                transform: translateY(-50%) rotate(90deg);
            }
        }

    }

    &__quill {
        max-height: 0;
        overflow: hidden;
        transition: 0.3s;

        &--open {
            max-height: 500px;
            overflow: hidden;
        }
    }

    &__item {
        &:first-of-type {
            .logbook__heading {
                border-top: none;
            }
        }
    }
}

.logbook-editor {
    min-height: 300px;
    display: flex;
    flex-direction: column;

    .ql-container {
        height: 200px;
        flex-grow: 1;
    }
}

.logbook {
    &__alert {
        border: 2px solid #ffd100;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;

        &-icon {
            height: 100%;
            background-color: #ffd100;
            color: #fff;
            align-self: stretch;
            font-size: 2rem;
            padding: 1rem;
        }

        &-content {
            padding: 1rem;

            p {
                margin: 0;
            }
        }
    }
}
</style>