import store from '../store'
import axios from "axios";

export function authHeader() {
	const vuex = store

	// return authorization header with jwt token
	let user =  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null

	vuex.commit('auth/setToken', token)
	vuex.commit('auth/setUser', user)
		
	if (user && token) {
		axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

		return true;
	} else {
		return false;
	}
}
