<script>
import moment from 'moment';
import request from '../../../services/request'
import { mapActions } from 'vuex';

export default {
    components: {},
    props: {
        event: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            elapsedTime: moment.duration(0),
            roulette: null,
            roulette_options: {
                word: '',
                time: ''
            },
            currentWord: null,
            words_use: null,
            animation: 1,
            roulete_active: false,
            attempts: 0,
            available_attempts: 5,
            success_attempts: 0,
            loadingRandom: false,
            status_game: false,
            showData: false,
            finish: false,
            roulette_audio: '../../assets/audio/Sonido_ruleta_01.wav',
            notification_audio: '../../assets/audio/Notificacion_02.wav'
        }
    },
    created() {
        let dinamic = this.event;
        this.roulette_options.word = dinamic.data.word;
        this.roulette_options.time = dinamic.data.time;

        window.Echo.channel(`statusGame.${dinamic.pivot.id}`)
            .listen('StatusGame', (e) => {
                if (e) {
                    console.log(e);
                    let event = e.game;
                    if (event.finish !== this.finish) {
                        this.finish = event.finish;
                        this.startRoulette();
                    }
                }
            })
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            if (!this.data) {
                this.roulette = this.roulette_options;
            } else {
                this.roulette = this.data;
            }
        },
        startRoulette() {
            if (!this.roulete_active) {
                this.$refs.audioRoulette.play();
                this.sendStatus();
                this.finish = true;
                this.roulete_active = true;
                this.hideModal();
                const ruleta = this.$refs.ruleta;
                const rotationAngle = Math.floor(Math.random() * 375) + 1440;
                ruleta.style.transition = "transform 4s ease-out";
                ruleta.style.transform = `translate(-50%, -50%) rotate(${rotationAngle}deg)`;
                setTimeout(() => {
                    this.$refs.audioRoulette.pause();
                    this.$refs.audioNotification.play();
                    this.roulete_active = false;
                    this.showModal();
                    ruleta.style.transition = "";
                    ruleta.style.transform = `translate(-50%, -50%) rotate(${rotationAngle % 360}deg)`;
                }, 4000);
            }
        },
        hideModal() {
            this.showData = false;
        },
        showModal() {
            this.showData = true;
        },
        startGame() {
            // Event.$emit('start-timer', this.currentWord.data.time);
            this.attempts += 1;
            this.status_game = true;
            this.finish = true;
            this.sendStatus();
            let dinamic = this.event;
            dinamic.status = 1;
            Event.$emit('finish-game', dinamic);
        },
        async sendStatus() {
            let dinamic = this.event;
            dinamic.status = 1;
            Event.$emit('finish-game', dinamic);

            let _endpoint = '';
            let event = this.event;
            event.status = 1;
            event.status_game = this.status_game;
            _endpoint = `${this.event.pivot.events_id}/status_game`;

            try {
                let { data } = await request.post(_endpoint, event)
                if (data) {
                    console.log(data);
                }
            } catch (error) {
                this.load = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>
<template>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-12">
            <div class="mb-2">
                <div class="roulette">
                    <img src="@/assets/img/bg-roulette.png" class="roulette-bg" width="380px" />
                    <img ref="ruleta" src="@/assets/img/roule-2.png" class="roulette-main" width="400px" />
                    <h3 class="roulette-text animate__animated animate__pulse animate__infinite">Cuéntame<br>más</h3>
                    <img src="@/assets/img/selector-rou.png" class="roulette-select" width="40px" />
                <div v-if="showData" class="box-absolute box-border box-shadow box-size bg-white box-front" :class="{
                    'animate__animated animate__jackInTheBox': showData
                }">
                    <h1 class="box-word" v-if="roulette">
                        {{ roulette.word }}
                    </h1>
                </div>
                </div>
            </div>
            <!-- <div class="text-center" v-if="finish === false">
                <button style="max-width: 500px; margin: 0 auto;" v-if="!roulete_active" class="btn btn-primary btn-block" @click="startRoulette">
                    ¡Girar Ruleta! </button>
            </div> -->
        </div>
        <!-- Modal para mensaje -->
        <!-- <modal name="topicRoulette" v-bind:click-to-close="true" :draggable="false" :width="700" :adaptive="true">
            <div class="box-border">
                <h1 class="box-word" v-if="roulette">
                    {{ roulette.word }}
                </h1>
            </div>
        </modal> -->
        <audio ref="audioRoulette" :src="roulette_audio"></audio>
        <audio ref="audioNotification" :src="notification_audio"></audio>
    </div>
</template>

<style lang="scss" scoped>




.roulette {
    position: relative;
    width: 100%;
    height: 550px;
    //border-radius: 50% !important;
    display: flex;
    overflow: hidden;

    &-bg {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-60%, -60%);
    }

    &-main {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        border-radius: 50% !important;
        transform: translate(-50%, -50%) rotate(15deg);
        //transform: rotate(15deg);
    }

    &-text {
        position: absolute;
        font-weight: bold;
        z-index: 12;
        top: 45%;
        left: 42%;
        text-align: center;
        transform: translate(-50%, -50%);
    }

    &-select {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 16.5%;
        transform: translate(-60%, -60%);
    }
}

.box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    gap: 10px;

    &-shadow {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &-front {
        z-index: 20;
    }

    &-absolute {
        position: absolute;
        top: 40%;
        /* left: 50%;
        transform: translate(-50%, -50%); */
    }

    &-size {
        width: 100% !important;
    }

    &-border {
        padding: 20px;
        border: 4px solid #FFD100;
        width: 100%;
        height: auto;
        border-radius: 20px;
    }

    &-center {
        margin: auto;
        text-align: center;
        align-self: center;
    }

    &-word {
        text-align: center;
        font-weight: bold;
        color: #76777A;
    }

    &-status-game {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #76777A;
        font-size: small;
    }
}

.btn {

    &-primary {
        color: white !important;
        background: #F29200;
        border: 2px solid #F29200 !important;
    }

    &-outline-primary {
        color: #F29200;
        background: white;
        border: 2px solid #F29200 !important;
    }

}


@media (max-width: 1199px) {
    .roulette {
        &-text {
            position: absolute;
            font-weight: bold;
            z-index: 12;
            top: 45%;
            left: 41%;
            text-align: center;
            transform: translate(-50%, -50%);
        }
    }
}

@media (max-width: 1029px) {
    .roulette {
        &-text {
            position: absolute;
            font-weight: bold;
            z-index: 12;
            top: 45%;
            left: 40%;
            text-align: center;
            transform: translate(-50%, -50%);
        }
    }
}

@media (max-width: 991px) {
    .roulette {
        &-text {
            position: absolute;
            font-weight: bold;
            z-index: 12;
            top: 45%;
            left: 37%;
            text-align: center;
            transform: translate(-50%, -50%);
        }
    }
}

@media (max-width: 768px) {
    .roulette {
        &-text {
            position: absolute;
            font-weight: bold;
            z-index: 12;
            top: 45%;
            left: 34%;
            text-align: center;
            transform: translate(-50%, -50%);
        }
    }
}

@media (max-width: 768px) {
    .roulette {
        &-text {
            position: absolute;
            font-weight: bold;
            z-index: 12;
            top: 45%;
            left: 37%;
            text-align: center;
            transform: translate(-50%, -50%);
        }
    }
}

</style>