<script>
export default {
    data() {
        return {
            showing: false,
            loading: false,
            loadingMessage: 'Cargando'
        }
    },
    methods: {
        open() {
            this.showing = true
        },
        close() {
            this.showing = false
        },
        setLoading(loading = true, message = 'Cargando') {
            this.loading = loading
            this.loadingMessage = message
        }
    }
}
</script>
<template>
    <div class="modal" v-if="showing">
        <div class="modal__wrap">
            <div class="modal__content">
                <slot name="content"></slot>

                <div class="modal__loading" v-if="loading">
                    <h4 class="p-2 text-2xl">{{ loadingMessage }}</h4>
                    <div>
                        <div class="lds-dual-ring"></div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</template>
<style lang="scss" scoped>
.modal{
    background-color: rgba($color: #fff, $alpha: 0.1);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 100;
    overflow-y: auto;
    &__wrap{
        max-width: 30rem;
        width: 80%;
        margin: auto;
        background-color: #fff;
        box-shadow: 4px 4px 3px rgba(#261962, 0.2);
        border: 1px solid #f1f1f1;
        background-size: 25px;
        border-radius: 1rem;
        padding: 1rem;
    }
    &__content{
        position: relative;
    }
    &__loading{
        background-color: #fff;
        background-size: 25px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #e42313;
  border-color: #e42313 transparent #e42313 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>