<script>
import LogBookForm from '@/components/LogBook/LogBookForm.vue';
export default {
    components: {
        LogBookForm
    },
    data() {
        return {}
    },
}
</script>
<template>
    <div class="max-w-lg mx-auto">
        <LogBookForm/>
    </div>
</template>
<style lang="scss">
.max-w-lg{
    max-width: 32rem;
}
</style>