<template>
	<div class="col-12">
		<pictionary-presenter-component></pictionary-presenter-component>
	</div>
</template>

<script>
import request from '../../services/request'
import moment from 'moment';
import '../../helpers/filters'
import PictionaryPresenterComponent from '@/components/Dinamics/Presenter/PictionaryPresenterComponent.vue';

export default {
	components: {
        PictionaryPresenterComponent
	},
	data() {
		return {
			load_elemnt: true,
			search: {
				name: '',
				_init: moment().format('YYYY-MM-DD')
			},
			events: [{
				data: []
			}]
		}
	},
	methods: {
		async list(page = 1) {
			this.load_elemnt = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/events';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					this.events = data;
					this.load_elemnt = false;
				}
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
	},
	mounted() {
		this.list();
	}
}
</script>

<style scoped>
.btn_add {
	bottom: 20px;
	position: fixed;
	right: 20px;
}

.bg-secondary {
	background-color: #98989a;
}

.pagination {
	margin-bottom: 0;
}

.b-logo {
	border-radius: 5px 55px 55px 5px;
	width: 120px;
	z-index: 1;
}

.dt_slider::before {
	content: "";
	background-color: #6c757d;
	height: 29px;
	position: absolute;
	width: 100px;
	bottom: 0px;
	left: -100px;
}

.dt_slider {
	bottom: 0px;
	position: absolute;
	width: calc(100% - 130px);
	border-radius: 0px 0px 5px 0px;
}

.shadow {
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
</style>
