<template>
    <div class="row m-0 bg align-items-center justify-content-center">
        <div class="login-box">

            <div class="col-12 text-center mb-4">
                <img src="@/assets/img/password.png" class="mt-3 mb-4" alt="Logo" width="150px;"><br>
                <h4 class="login-box-msg mt-n2">Modificar contraseña</h4>
                <p class="mt-n3">Ingresa los datos para recuperar tu contraseña</p>
            </div>
            <form @submit.prevent="triggerChangePassword">
                <div class="col-12 mb-3">
                    <div class="input-group ">
                        <input type="text" v-model="form.email" name="email" class="form-control" placeholder="Correo"
                            :class="{ 'is-invalid': submitted && !form.email }" />
                    </div>
                    <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
                </div>
                <div class="col-12">
                    <div class="input-group" style="margin-bottom:6px !important;">
                        <input :type="show ? 'text' : 'password'" v-model="form.password" name="password" class="form-control input-pass"
                            placeholder="Contraseña" :class="{ 'is-invalid': submitted && !errors.password }" />
                    </div>
                    <button type="button" class="btn btn-sm btn-danger btn-pass" @click="show = show ? false : true">
						<i v-if="show" class="fa-solid fa-eye-slash"></i>
						<i v-else class="fa-solid fa-eye"></i>
					</button>
                    <div v-if="errors && errors.password" class="text-danger">{{ errors.password[0] }}</div>
                </div>
                <div class="form-group text-center mt-3">
                    <button class="btn btn-danger btn-block" style="max-width: 250px; margin: auto;" :disabled="submitted">
                        Modificar
                    </button>
                </div>
                <div class="col-md-12 text-center mt-5">
                    <router-link class=" text-danger" to="/login">Iniciar sesión</router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
    },
    data() {
        return {
            form: {
                passwordToken: '',
                email: '',
                password: '',
            },
            errors: {},
            submitted: false,
			show: false,
        }
    },
    methods: {
        async triggerChangePassword() {
            try {
                let reset = await this.changePassword(this.form)
                if (reset) {
                    this.successNotification(reset.message);
                    this.$router.push('/login')
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        ...mapActions({
            changePassword: 'auth/changepass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        let tkn = this.$route.query.token;
        if (tkn) {
            this.form.passwordToken = tkn;
        } else {
            this.errorNotification('Comprueba la URL para modificar la contraseña.');
            this.$router.push('/login')
        }
    },
};
</script>
<style>
.btn-pass {
	float: right;
	margin-top: -40px;
	margin-right: 4px;
	border-radius: 20px;
    z-index: 10;
    position: relative;
}

.input-pass {
	padding-right: 46px;
}
</style>