<template>
    <div class="row m-0 bg align-items-center justify-content-center">
        <div class="login-box">

            <div class="col-12 text-center mb-4">
				<img src="@/assets/img/password.png" class="mt-3 mb-4" alt="Logo" width="150px;"><br>
				<h3 class="login-box-msg mt-n2">Restablecer contraseña</h3>
				<p class="mt-n3">Ingresa el correo para enviar el link</p>
			</div>
            <form @submit.prevent="triggerResetPassword">
                <div class="col-12 mb-3 text-center">
                    <div class="input-group ">
                        <input type="text" v-model="form.email" name="email" class="form-control" placeholder="Correo"
                            :class="{ 'is-invalid': submitted && !form.email }" />
                    </div>
                    <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
                </div>
                <div class="form-group text-center">
                    <button class="btn btn-danger btn-block" style="max-width: 250px; margin: auto;" :disabled="submitted">Restablecer
                        contraseña </button>
                </div>
                <div class="col-md-12 text-center mt-5">
                    <router-link class=" text-danger" to="/login">Iniciar sesión</router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
    },
    data() {
        return {
            form: {
                email: '',
            },
            errors: {},
            submitted: false
        }
    },
    methods: {
        async triggerResetPassword() {
            try {
                console.log(this.form);
                let reset = await this.resetPassword(this.form)
                if (reset) {
                    this.successNotification(reset.message);
                    this.$router.push('/login')
                }
            } catch (error) {
                console.log(error);
                console.log(error.response);
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
};
</script>