<script>
export default{
    props: {
        pagination: {
            type: [Object, Boolean],
            default: false
        },
        limit: {
            type: Number,
            default: 3
        },
        lateral: {
            type: Number,
            default: 3
        }
    },
    computed: {
        pages() {
            let vm = this;
            let res = [];
            if (vm.pagination) {
                let current_page = vm.pagination.current_page;
                let last_page = vm.pagination.last_page;
                if (current_page >= vm.lateral) {
                    for (let index = (current_page - (vm.lateral-1)); index <= current_page; index++) {
                        res.push(index);
                    }
                } else {
                    for (let index = 1; index <= current_page; index++) {
                        res.push(index);
                    }
                }
                for (let index = 1; index <= vm.limit; index++) {
                    if (res.length < vm.limit && (current_page + index) <= last_page) {
                        res.push(current_page + index);
                    }
                }
            }
            return res;
        }
    },
    methods: {
        clicPage(_n) {
            let vm = this;
            if (vm.pagination.current_page!=_n) {
                this.$emit('change-page', _n);
            }
        }
    }
}
</script>
<template>
    <nav aria-label="Page navigation example" v-if="pagination">
        <ul class="pagination">
            <li class="page-item">
                <button type="button" class="btn btn-outline-primary" v-if="pagination.prev_page_url" @click="clicPage(pagination.current_page - 1)"> Anterior </button>
                <button type="button" class="btn btn-outline-primary disabled" v-else> Anterior </button>
            </li>
            <li class="page-item">
                <button type="button" class="btn btn-outline-primary" v-for="pageNumber in pages" @click="clicPage(pageNumber)" :key="pageNumber"
                :class="{'active' : pageNumber==pagination.current_page}"> {{pageNumber}} </button>
            </li>
            <li class="page-item">
                <button type="button" @click="clicPage(pagination.current_page + 1)" v-if="pagination.next_page_url" class="btn btn-outline-primary"> Siguiente </button>
                <button type="button" class="btn btn-outline-primary disabled" v-else> Siguiente </button>
            </li>
        </ul>
    </nav>	
</template>
