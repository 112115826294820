import Vue from 'vue';

import moment from 'moment';
moment.locale('es');

Vue.filter('dateMinutHuman',function(value) {
	if(value){
    return moment(value).format('DD/MMM/YYYY hh:mm A');
	}else{
		return 'Sin Fecha';
	}
});

Vue.filter('dateHuman',function(value) {
	if(value){
    return moment(value).format('DD/MM/YYYY');
	}else{
		return 'Sin Fecha';
	}
});

Vue.filter('dateName',function(value) {
	if(value){
    return moment(value).format('LL');
	}else{
		return 'Sin Fecha';
	}
});
