import axios from "axios"


let ENDPOINT = '';
let urlBase = window.location.hostname;
if (urlBase == 'localhost') {
    ENDPOINT = 'http://127.0.0.1:8000/api/auth';
}

if (urlBase == 'staging-conectando-lideres.sorianoariza.com') {
     ENDPOINT = 'https://api-staging-conectando-lideres.sorianoariza.com/api/auth';
}

if (urlBase == 'sraz.mx') {
     ENDPOINT = 'https://api-conectando-lideres.sraz.mx/api/auth';
}

if (urlBase == 'dev.sraz.mx' || urlBase == 'www.dev.sraz.mx') {
     ENDPOINT = 'https://api-dev.sraz.mx/api/auth';
}

export const login = (payload) => {
    let endpoint = `${ENDPOINT}/login`
    return axios.post(
        endpoint,
        payload
    )
}

export const register = () => {
    let endpoint = `${ENDPOINT}/register`
    return axios.post(
        endpoint,
    )
}

export const logout = () => {
    let endpoint = `${ENDPOINT}/logout`
    return axios.post(endpoint)
}

export const getUser = () => {
    let endpoint = `${ENDPOINT}/me`
    return axios.get(endpoint);
}

export const updateUser = () => {
    let endpoint = `${ENDPOINT}/user`
    return axios.post(endpoint,)
}


export default {
    login,
}
