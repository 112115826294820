<template>
    <div class="row bg align-items-center justify-content-center">
        <div class="col-10" v-if="event.data_end">
            <div class="col-12 text-center p-3">
                <img src="@/assets/img/logo_grupo_elektra.png" class="mb-4" style="width: 100%; max-width: 700px;"><br>
                <h1> El evento ha terminado </h1>
                <p> muchas gracias por tu participación </p>
            </div>
        </div>
        <div v-else class="col-10 col-md-6">
            <div class="row" v-if="!registerOk">
                <div class="col-12 text-center">
                    <img src="@/assets/img/user.png" class="mt-3 mb-4" alt="Logo" width="150px;"><br>
                </div>
            </div>


            <div v-if="registerOk" class="row">
                <div class="col-12 p-5 text-center" v-if="countime">
                    <h3 class="mb-4">Ingresa haciendo clic en el boton</h3>
                    <a :href="event.url" target="_blank" class="btn btn-lg btn-danger mb-4">
                        Ingresar
                    </a>
                    <p>
                        o también puedes copiar el siguiente link en tu navegador <br><br>
                        {{ event.url }}
                    </p>
                </div>
                <div class="col-12 p-5 text-center" v-else>
                    <h2 class="mb-5">Para iniciar el evento falta</h2>
                    <flip-countdown :deadline="`${event.init_date} ${event.init_time}`"
                        :labels="{ days: `Días`, hours: `Horas`, minutes: `Minutos`, seconds: `Segundos` }"></flip-countdown>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 p-5 text-center" v-if="loadMod">
                    <i class="fa-solid fa-spinner fa-spin-pulse fa-5x mb-3"></i>
                    <h4>Cargando</h4>
                </div>
                <div class="col-12" v-else>
                    <form-wizard @on-complete="onComplete" ref="wizard" :title="`Captura de datos`" subtitle="Participante"
                        back-button-text="Regresa" next-button-text="Siguiente" finish-button-text="Enviar">
                        <tab-content title="Detalles personales" icon="ti-user" v-if="user.validation == 0"
                            :before-change="() => validadeUser()">
                            <div class="col-12 mb-1 mt-3">
                                <input type="text" v-model="user.name" class="form-control no_shadow" placeholder="Nombres">
                                <div class="text-danger" v-if="sending1 && !$v.user.name.required">El nombre
                                    es requerido</div>
                            </div>
                            <div class="col-12 mb-1 mt-3">
                                <input type="text" v-model="user.last_name" class="form-control no_shadow"
                                    placeholder="Apellidos">
                                <div class="text-danger" v-if="sending1 && !$v.user.last_name.required">El nombre
                                    es requerido</div>
                            </div>
                            <div class="col-12 mb-1 mt-3">
                                <input type="text" v-model="user.email" class="form-control no_shadow" readonly
                                    placeholder="Correo">
                                <div class="text-danger" v-if="sending1 && !$v.user.email.required">El correo
                                    es requerido</div>
                                <div class="text-danger" v-if="sending1 && !$v.user.email.email">El correo no es
                                    valido.</div>
                            </div>
                            <div class="col-12 mb-1 mt-3">
                                <input type="text" v-model="user.code" class="form-control no_shadow"
                                    placeholder="Número de empleado">
                                <div class="text-danger" v-if="sending1 && !$v.user.code.required">El número de empleado
                                    es requerido</div>
                                <div class="text-danger" v-if="sending1 && !$v.user.code.integer">El número de empleado
                                    debe
                                    ser solo números.</div>
                            </div>
                            <div class="col-12 mb-1 mt-3">
                                <input type="text" v-model="user.phone" class="form-control no_shadow"
                                    placeholder="Teléfono">
                                <div class="text-danger" v-if="sending1 && !$v.user.phone.required">El teléfono
                                    es requerido</div>
                            </div>
                        </tab-content>
                        <tab-content title="Preguntas para el evento" icon="ti-help">
                            <div class="col-12 mb-1 mt-3" v-for="(question, index_question) in questionnaires.questios"
                                :key="index_question">
                                <!-- <label for="">{{ question.name }}</label> -->
                                <!-- Respuesta corta -->
                                <input v-if="question.question_types_id == 1" type="text" v-model="responses[question.id]"
                                    class="form-control no_shadow" :placeholder="question.name">
                                <!-- Parrafo -->
                                <textarea v-if="question.question_types_id == 2" v-model="responses[question.id]"
                                    class="form-control no_shadow" :placeholder="question.name"></textarea>
                                <!-- Verificación -->
                                <label class="Nquestion"
                                    v-if="question.question_types_id == 3 || question.question_types_id == 4">{{
                                        question.name }}</label>

                                <div class="row justify-content-center" v-if="question.question_types_id == 3">
                                    <div class="form-check  text-center col-md-4 mt-2"
                                        v-for="(answar, index_answar) in question.answares" :key="index_answar">
                                        <input type="radio" class="form-check-input" :value="answar.id"
                                            v-model="responses[question.id]">
                                        <label class="ml-3"> {{ answar.name }} </label>
                                    </div>
                                </div>
                                <!-- Lista -->
                                <select v-if="question.question_types_id == 4" v-model="responses[question.id]"
                                    class="form-control no_shadow">
                                    <option>Seleciona una opción</option>
                                    <option v-for="(answar, index_answar) in question.answares" :key="index_answar"
                                        :value="answar.id"> {{ answar.name }}</option>
                                </select>
                            </div>
                        </tab-content>
                    </form-wizard>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import request from '../../services/request'
import { mapActions } from 'vuex';
import { required, email, integer } from 'vuelidate/lib/validators';
import FlipCountdown from 'vue2-flip-countdown'

export default {
    validations: {
        user: {
            name: {
                required
            },
            last_name: {
                required
            },
            email: {
                required,
                email
            },
            code: {
                required,
                integer
            },
            phone: {
                required
            }
        }
    },
    components: { FlipCountdown },
    data() {
        return {
            user: {
                id: '',
                name: '',
                last_name: '',
                email: '',
                code: '',
                phone: '',
                validation: ''
            },
            loadMod: true,
            event: {
                id: '',
                name: '',
                init_date: '',
                init_time: '',
                url: '',
                data_end: ''
            },
            questionnaires: [],
            responses: [],
            sending1: false,
            registerOk: false,
            countime: false,
            currentTime: 0
        }
    },
    mounted() {
        let tkn = this.$route.params.tkn;
        if (tkn) {
            this.get_register(tkn);
        }
        window.setInterval(() => {
            this.currentTime = new Date()
        }, 1000)
    },
    watch: {
        async currentTime() {
            const today = new Date();
            const iniEvent = new Date(`${this.event.init_date} ${this.event.init_time}`);
            if (today >= iniEvent) {
                this.countime = true;
            }
        }
    },
    methods: {
        async get_register(tkn) {
            let _endpoint = `/register-user?tkn=${tkn}`;
            try {
                let { data } = await request.get(_endpoint)
                // event
                let event = this.event;
                Object.keys(event).forEach(key => {
                    this.event[key] = data.event[key];
                });
                this.event.name = data.event['event'];
                this.event.id = data.event['event_id'];
                // user
                let user = this.user;
                Object.keys(user).forEach(key => {
                    this.user[key] = data.event[key];
                });
                this.questionnaires = data.questionnaires;
                this.responses = data.response_user;
                this.loadMod = false;
                if (data.event['validation_event'] === 1) {
                    this.registerOk = true;
                }
                if (data.event['validation'] === 1) {
                    this.$refs.wizard.nextTab();
                }


            } catch (error) {
                this.errors = {};
                if (error.response) {
                    console.log(error);
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        validadeUser() {
            this.sending1 = true;
            if (this.$v.user.$invalid === true) return false;
            return true;
        },
        async onComplete() {
            // this.loadMod = true;
            let _endpoint = `/send-register-user`;
            let form = {
                tkn: this.$route.params.tkn,
                event_id: this.event.id,
                id: this.user.id,
                name: this.user.name,
                last_name: this.user.last_name,
                email: this.user.email,
                code: this.user.code,
                phone: this.user.phone,
                validation: this.user.validation,
                response: this.responses
            };

            try {
                let { data } = await request.post(_endpoint, form)
                if (data) {
                    this.registerOk = true;
                }
            } catch (error) {
                this.load = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>
<style>
@import "https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css";

.wizard-nav-pills {
    display: none !important;
}

.wizard-progress-with-circle {
    display: none !important;
}

.wizard-tab-content {
    padding: 10px 20px 10px;
}

.wizard-card-footer {
    padding: 25px !important;
}

.vue-form-wizard .wizard-header {
    padding: 0px;
}

.vue-form-wizard .wizard-title {
    font-size: 40px;
    ;
}

.vue-form-wizard .category {
    font-size: 25px;
}

.Nquestion {
    width: 100%;
    background: white;
    padding: 6px 15px;
    border-radius: 35px;
    border: 1px solid #ced4db;
}

.wizard .wizard-btn {
    background-color: rgb(231, 76, 60) !important;
    border-color: rgb(231, 76, 60) !important;
    color: white;
}

.form-check-input {
    transform: scale(2.0);
    -webkit-transform: scale(2.0);
}
</style>