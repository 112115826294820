<script>
import request from '../../services/request'
export default {
    data() {
        return {
            title: 'Respuestas del participante',
            user_name: '',
            questionnaire: {
                id: '',
                questions: []
            },
            errors: {},
            load: false,
        }
    },
    methods: {
        async get_id(id) {
            this.questionnaire.id = id;
            let _endpoint = `/questionnaires_event/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    this.questionnaire.questions = data.questios;
                }
            } catch (error) {
                this.errors = {};
                if (error.answare) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        async get_responses(id) {
            let params = {
                participant_id: id,
                event_id: this.questionnaire.id
            }
            let _endpoint = `/responses_participant_event`;
            let questions = this.questionnaire.questions;
            try {
                let { data } = await request.get(_endpoint, params)
                if (data) {
                    questions.forEach(function (question) {
                        let _response = data.filter(res => res.questions_id == question.id);
                        let responseUser = 'Sin respuesta';
                        if (question.question_types_id == 1 || question.question_types_id == 2) {
                            if (_response) {
                                responseUser = _response[0].answers;
                            }
                        }
                        if (question.question_types_id == 3 || question.question_types_id == 4) {
                            if (_response) {
                                let _answaresQuestion = question.answares;
                                let _responseQ = _answaresQuestion.filter(answares => answares.id == parseInt(_response[0].answers));
                                if (_responseQ) {
                                    responseUser = _responseQ[0].name;
                                }
                            }
                        }
                        question.required = responseUser;
                    })
                }                
                this.questionnaire.questions = questions;
            } catch (error) {
                this.errors = {};
                if (error.answare) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        reset() {
            this.$modal.hide('ResponsesUser');
            let id = this.$route.params.id;
            this.get_id(id);
        }
    },
    mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.questionnaire.proyect_id = id;
            this.get_id(id);
        }

    },
    created() {
        Event.$on("event-responses-user", user => {
            if (user) {
                this.user_name = `${user.name} ${user.last_name}`;
                this.get_responses(user.id);
            }
        });
    },
}
</script>
<template>
    <modal name="ResponsesUser" v-bind:click-to-close="false" :width="600" :adaptive="true">
        <div class="row p-4" v-if="load == false">
            <div class="col-12">
                <h4>{{ title }} - {{ user_name }}</h4>
                <hr>
            </div>
            <div class="col-12" v-for="(question, index_question) in questionnaire.questions" :key="index_question">
                <h4>{{ question.name }} :</h4>
                <p v-if="question.required != 0">{{ question.required }}</p>
                <p v-else>Sin respuesta</p>
            </div>
            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-outline-warning" @click="reset">
                            <i class="fas fa-undo-alt"></i>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-12 text-center p-4">
                <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                <h5 class="mt-3">Enviando...</h5>
            </div>
        </div>
    </modal>
</template>