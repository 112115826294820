<template>
    <div class="col-12 mt-3">
        <UserForm/>
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Lista de usuarios</h3>
                <button class="btn btn-sm btn-success float-right" @click="showModalUser()">
                    <i class="fa fa-plus"></i> Agregar
                </button>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Perfil</th>
                                <th>Estatus</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody v-if="users">
							<tr v-for="(user, index) in users.data" :key="index">
								<td>{{ user.id }}</td>
								<td>{{ user.name }}</td>
								<td>{{ user.email }}</td>
								<td>
									<strong>{{ user.profile.name }}</strong>
								</td>
                                <td>
                                    <h6>
                                        <span class="badge" :class="user.statuses_id == 1 ? ' badge-success': ' badge-danger'">{{ user.status.name }}</span>
                                    </h6>
								</td>
								<td>
									<button @click=showModalUser(user.id) class="btn btn-primary btn-sm hint--bottom" aria-label="Editar">
										<i class="fas fa-edit "> </i>
									</button>
									<button type="button" @click="deleted(user.id)" class="ml-2 btn-danger btn btn-sm hint--bottom" aria-label="Eliminar">
										<i class="fa fa-trash"></i>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
                    </table>
					<div class="col-12">
						<div class="row">
							<div class="col-md-6 col-12 text-left mt-md-2">
								<strong> Mostrando del {{ users.from }} al {{ users.to }} de {{ users.total }} </strong>
							</div>
							<div class="col-md-6 col-12 text-right">
								<Pagination :prevButtonText="'Anterior'" :containerClass="'pagination'" :nextButtonText="'Siguiente'" :changePage="list" :data="users"/>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserForm from '@/components/Users/UserFormComponent.vue';
import request from '../../services/request'
import Pagination from 'vue-laravel-paginex'
import { mapActions } from 'vuex';

export default{
    components: {
		UserForm,
		Pagination
	},
    data(){
        return {
            search:{
                name : ''
            },
            users:[]
        }
    },
    methods: {
        showModalUser(id = '') {
			Event.$emit('event-new-users', id);
			this.$modal.show('Newusers');
		},
        deleted: function (id) {
			let tit = 'Eliminar usuario';
			let txt = 'Seguro que quiere eliminar al usuario';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/users/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		async list(data = {}) {
			console.log(data);
			let page = 1;
			if (data.page) {
				page = data.page;
			}			
			let service = request;
			let _endpoint = '/users';
			try {
				let { data } = await service.list(_endpoint, page, {})
				if (data) {
					this.users = data;
				}
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    mounted() {
        this.list();
	},
	created() {
		Event.$on("event-load-users", () => {
				this.list();
		});
	},
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}
</style>
