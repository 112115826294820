<template>
	<div class="full_load">
		<div class="row align-items-center justify-content-center h-100">
			<grid-loader :loading="loading" :color="color" :size="size"></grid-loader>
		</div>
	</div>
</template>
<script>
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'
// import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
// import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
	components: {
		// PulseLoader,
		GridLoader,
		// BounceLoader
		// ScaleLoader
	},
	data() {
		return {
			loading: true,
			color: '#b91702ab',
			size: '35px',
		}
	},
}
</script>
<style scoped>
.full_load {
	background-color: #4a4a4a75;
	/* background-color: #065e4cf5; */
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 1087;
	backdrop-filter: blur(4px);
}
</style>
