<script>
import WildcardForm from '@/components/Wildcards/WildcardFormComponent.vue';
import request from '../../services/request';
import { mapActions } from 'vuex';
export default {
    components: {
        WildcardForm
    },
    data() {
        return {
            wildcards: [],
        }
    },
    created() {
        Event.$on('event-load-wildcards', () => {
            this.list();
        })
    },
    mounted(){
        this.list();
    },
    methods: {
        showModalQuestion(id){
            Event.$emit('event-new-wildcards', id);
            this.$modal.show('Newwildcard');
        },
        async list(page = 1){
            let service = request;
            let _endpoint = '/wildcards';
            try{
                let { data } = await service.list(_endpoint, page, {});
                if(data){
                    this.wildcards = data;
                }
            }catch(error) {
                this.errors = await this.errorNotificacion(error);
            }
        },

        async changeStatus(id){
            let _endpoint = `/wildcards/${id}`;
            try{
                let response = await request.destroy(_endpoint);
				this.$snotify.success(response.data.message);
            }catch(error){
				this.$snotify.error('Ups. Parece que algo salio mal...');

            }
        },
        ...mapActions({
            errorNotificacion: 'alert/error',
            successNotification: 'alert/success'
        })
    }
}
</script>

<template>
    <div>
        <wildcard-form></wildcard-form>
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Listado de preguntas</h3>
                <button class="btn btn-sm btn-success float-right" @click="showModalQuestion('')">
                    <i class="fa fa-plus"></i> Agregar
                </button>
            </div>
            <div class="card-body">
                <div class="table-reponsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Tipo de comodín</th>
                                <th>Comodín</th>
                                <th>Estado</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody v-if="wildcards">
                            <tr v-for="(wildcard, index) in wildcards.data" :key="index">
                                <td>{{ wildcard.id }}</td>
                                <td>{{ wildcard.question_type }}</td>
                                <td>{{ wildcard.question }}</td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" v-model="wildcard.statuses_id" @change="changeStatus(wildcard.id)">
                                        <div class="switch-border"></div>
                                        <span class="switch-indicator"></span>
                                    </label>
                                </td>
                                <td>
                                    <button class="btn btn-primary btn-sm" @click="showModalQuestion(wildcard.id)">
										<i class="fas fa-edit "> </i>
									</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.switch{
    position: relative;
    height: 20px;
    width: 40px;
    cursor: pointer;
    &-border{
        background-color: #fff;
        position: absolute;
        border: 2px solid #808180;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 40px;
        transition: 0.3s;
    }
    &-indicator{
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        border: 2px solid #808180;
        height: 20px;
        width: 20px;
        border-radius: 40px;
        transition: 0.2s;

    }
    input[type="checkbox"] {
        display: none;
        &:checked{
            & ~ .switch-border{

                background-color: rgb(185, 0, 0);
            }
            & ~ .switch-indicator{
                left: 20px;
            }
        }
    }

}
</style>