import router from './../router';

export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null
    },
    actions: {
        success({ commit }, message) {
            commit('success', message);
        },
        error({ commit }, error = {}) {
            console.log(error.response.data);
            try {
                // Validación API
                if (error.response.status == 403) {
                    if (error.response.data.error == "Unauthenticated.") {
                        commit('error', 'Se ha cerrado la sesión de una cuenta');
                        this.commit('setUser', null);
                        this.commit('setToken', null);
                        router.push('/login')
                    }
                    // Validación Campos
                } else if (error.response.status == 401) {
                    if (error.response.data.message == "Unauthenticated.") {
                        commit('error', 'Se ha cerrado la sesión de una cuenta');
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('user');
                        location.reload();
                    } else {
                        commit('error', error.response.data.errors);
                        return Promise.resolve({});
                    }
                    // Validación Campos
                } else if (error.response.status == 422) {
                    return Promise.resolve(error.response.data.errors);
                } else {
                    commit('error', 'Ups. Parece que algo salio mal...');
                    return Promise.resolve({});
                }
                // commit('error', message);
            } catch (error) {
                console.log('promise', error);
            }
        },
        clear({ commit }) {
            try {
                commit('clear');
            } catch (error) {
                console.log('error clear', error);
            }
        }
    },
    mutations: {
        success(state, message) {
            console.log(message);
            console.log(state);
            try {
                state.type = 'success';
                state.message = message;
            } catch (error) {
                console.log('succes', error);
            }
        },
        error(state, message) {
            console.log(message);
            console.log(state);
            state.type = 'error';
            state.message = message;
        },
        clear(state) {
            state.type = null;
            state.message = null;
        }
    }
}
