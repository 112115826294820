export const user = (state) => {
    return state.user
}

export const team = (state) => {
    return state.team
}

export const token = (state) => {
    return state.token
}

export const permissions = (state) => {
    return state.permissions
}
