<script>
import request from '@/services/request';
import Pagination from 'vue-laravel-paginex'
import { mapActions } from 'vuex';

export default {
    components: {
        Pagination,
    },
    data() {
        return {
            dynamic_id: '',
            title: '',
            usersSelected: [],
            users: {
                data: []
            },
            load: false,
        }
    },
    methods: {
        async getUsers(data = {}) {
            this.load = true;
            let vm = this;
            let page = 1;
            if (data.page) {
                page = data.page;
            }
            let service = request;
            let _endpoint = '/users?profile=2';
            try {
                let { data } = await service.list(_endpoint, page, {})
                if (data) {
                    setTimeout(function () {
                        vm.load = false;
                    }, 500);

                    this.users = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async getUsersDynamic() {
            this.load = true;
            let service = request;
            let _endpoint = '/words_users?dynamic_id='+this.dynamic_id;
            try {
                let { data } = await service.get(_endpoint);

                let users = [];
                data.forEach(function (valor) {
                    users.push(valor.users_id);
                });

                this.usersSelected = users;
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },

        closedModal() {
            this.$modal.hide('ShowUsersDinamic');
        },
        async onChangeValue(user_id) {
            let form = {
                user_id: user_id,
                dynamic_id: this.dynamic_id
            };
            let vm = this;
            let _endpoint = '/words_users';
            let service = request;
            let { data } = await service.post(_endpoint, form);
            if (data) {
                this.$snotify.success(data.message);
                if (data.type == 'ADD') {
                    this.usersSelected.push(user_id);
                } else {
                    let position = vm.usersSelected.findIndex((element) => element == user_id);
                    console.log(position);
                    vm.usersSelected.splice(position, 1);
                }
                console.log(data);
            }
        },
        getUserCheck(id) {
            let users = this.usersSelected;
            return users.includes(id);
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-users-dinamyc", dynamic => {
            console.log(dynamic);
            this.title = '';
            if (dynamic) {
                this.dynamic_id = dynamic.id;
                this.title = dynamic.data.word;
                this.getUsersDynamic();
                this.getUsers();
                this.$modal.show('ShowUsersDinamic');
            }
        });
    }

}
</script>
<template>
    <modal name="ShowUsersDinamic" :width="700" :adaptive="true">
        <div class="row p-4" v-if="load == false">
            <div class="col-12">
                <button class="float-right btn btn-sm btn-danger" @click="closedModal()">
                    <i class="fa fa-times"></i>
                </button>
                <h6> Dinámica :</h6>
                <h4>{{ title }}</h4>
                <hr>
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table table-bordered text-center text-sm">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Nombre</td>
                                    <td>Correo</td>
                                    <td>Estatus</td>
                                </tr>
                            </thead>
                            <tbody v-if="users">
                                <tr v-for="(user, index) in users.data" :key="index">
                                    <td>{{ user.id }}</td>
                                    <td>{{ user.name }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>
                                        <toggle-button :value="getUserCheck(user.id)" @change="onChangeValue(user.id)"
                                            color="#d32600" :sync="true" :labels="false" />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td align="center" colspan="5">Sin resultados.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-12 text-center p-4">
                <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                <h5 class="mt-3">Cargando...</h5>
            </div>
        </div>
        <div class="row pr-4 pl-4 pb-4 mt-n3">
            <div class="col-md-6">
                <Pagination :prevButtonText="'Anterior'" :containerClass="'pagination'" :nextButtonText="'Siguiente'"
                    :changePage="getUsers" :data="users" />

            </div>
            <div class="col-md-6">
                <button class="float-right btn btn-danger" @click="closedModal()">
                    <i class="fa fa-times"></i> Cerrar
                </button>
            </div>
        </div>
    </modal>
</template>