import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import localStorage from 'vue-ls'
require('bootstrap')
import './assets/global.css';
import 'animate.css';
//global registration
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

import ToggleButton from 'vue-js-toggle-button'
 Vue.use(ToggleButton)

// setup fake backend
import { authHeader } from './helpers';
authHeader()

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import vmodal from 'vue-js-modal'
// import 'vue-js-modal/dist/styles.css'
Vue.use(vmodal);
Vue.use(localStorage)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import Echo from "laravel-echo"
import Pusher from 'pusher-js';
window.Pusher = Pusher;

let urlBase = window.location.hostname;
let urlConect = '';
let portConect = '';

if (urlBase == 'localhost') {
  urlConect = 'localhost';
  portConect = '6001';
}

if (urlBase == 'staging-conectando-lideres.sorianoariza.com') {
  urlConect = 'api-staging-conectando-lideres.sorianoariza.com';
  portConect = '6005';
}

if (urlBase == 'sraz.mx') {
  urlConect = 'api-conectando-lideres.sraz.mx';
  portConect = '6001';
}

if (urlBase == 'dev.sraz.mx' || urlBase == 'www.dev.sraz.mx') {
  urlConect = 'api-dev.sraz.mx';
  portConect = '6005';
}

console.log(urlConect);
console.log(portConect);

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'ASDASD2121',
  wsHost: urlConect,
  wssHost: urlConect,
  wsPort: portConect,
  wssPort: portConect,
  disableStats: true,
  forceTLS: true,
  enabledTransports: ['ws', 'wss'],
  // enabledTransports: ['wss'],
})

Vue.config.productionTip = false

window.Event = new Vue();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
