<script>
import RouletteComponent from '@/components/Dinamics/RouletteComponent.vue'
export default {
    components: {
        RouletteComponent
    }
}
</script>

<template>
    <div>
        <roulette-component></roulette-component>
    </div>
</template>

<style lang="scss" scoped>

</style>