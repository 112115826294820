<script>
// import ShareMenuPage from './ShareMenuPage.vue'
export default {
    // components: { ShareMenuPage },
    data() {
        return {

        }
    }
}
</script>
<template>
    <div class="wrapper">
        <div class="content-wrapper pt-3">
            <!-- <header class="w-100">
                <share-menu-page></share-menu-page>
            </header> -->
            <div class="col-12 pt-lg-5">
                <!-- Main content -->
                <section class="content">
                    <div class="container-fluid">
                        <div class="col-md-12 col-sm-12 col-12">
                            <router-view></router-view>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .btn-menu{
        position: absolute;
        right: 30px;
        z-index: 1;
    }
    .wrapper {
        padding: 0;
    }
    .content-wrapper{
        margin: 0 !important;
        background-image: url('@/assets/img/fondo.png');

        /* Center and scale the image nicely */
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>