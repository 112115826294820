<template>
	<modal name="Profileusers" v-bind:click-to-close="false" :width="400" :adaptive="true">
		<div class="row p-4" v-if="load == false">
			<div class="col-12">
				<h4 class="text-dark">{{ title }}</h4>
				<hr>
				<div class="form-group mb-2">
					<label class="text-dark" for="">Nombre :</label>
					<input v-model="form.name" class="form-control">
					<div v-if="(errors && errors.name)" class="text-danger ml-3">{{ errors.name[0] }}</div>
				</div>
				<div class="form-group mb-2">
					<label class="text-dark" for="">Correo :</label>
					<input v-model="form.email" readonly class="form-control">
					<div v-if="(errors && errors.email)" class="text-danger ml-3">{{ errors.email[0] }}</div>
				</div>
				<div class="form-group mb-2">
					<hr>
					<h6 class="text-info">Dejar en blanco si no quiere cambiar la contraseña</h6>
					<label class="text-dark" for="">Contraseña :</label>
					<input :type="show ? 'text' : 'password'" v-model="form.password" class="form-control input-pass">
					<button type="button" class="btn btn-sm btn-danger btn-pass" @click="show = show ? false : true">
						<i v-if="show" class="fa-solid fa-eye-slash"></i>
						<i v-else class="fa-solid fa-eye"></i>
					</button>
					<div v-if="(errors && errors.password)" class="text-danger ml-3">{{ errors.password[0] }}</div>
				</div>
				<div class="form-group mb-2">
					<label class="text-dark" for="">Confirmar contraseña :</label>
					<input :type="show2 ? 'text' : 'password'" v-model="form.password_confirmation"
						class="form-control input-pass">
					<button class="btn btn-sm btn-danger btn-pass" @click="show2 = show2 ? false : true">
						<i v-if="show2" class="fa-solid fa-eye-slash"></i>
						<i v-else class="fa-solid fa-eye"></i>
					</button>
					<div v-if="(errors && errors.password_confirmation)" class="text-danger ml-3">{{
						errors.password_confirmation[0] }}</div>
				</div>

			</div>
			<div class="col-12 mt-3">
				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-warning" @click="reset">
							<i class="fas fa-undo-alt"></i>
							Cerrar
						</button>
					</div>
					<div class="col-6 text-right">
						<button class="btn btn-outline-success" @click="save">
							<i class="fas fa-save"></i>
							Guardar
						</button>

					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12 text-center p-4 text-dark">
				<i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
				<h5 class="mt-3">Enviando...</h5>
			</div>
		</div>
	</modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'
import Catalogue from '../../services/catalogue';

export default {
	name: "new-users",
	data() {
		return {
			title: 'Editar Perfil',
			form: {
				id: '',
				name: '',
				email: '',
				profiles_id: '',
				password: '',
				password_confirmation: '',
				_method: 'POST'
			},
			show: false,
			show2: false,
			errors: {},
			catalogue: new Catalogue(),
			load: false,
		}
	},
	created() {
		Event.$on("event-form-profile", id => {
			if (id) {
				this.get_id(id);
			}
		});
	},
	mounted() {
		this.catalogue.get_profiles();
	},
	methods: {
		async save() {
			this.load = true;
			let _endpoint = '';
			if (this.form.id) {
				_endpoint = `/users/${this.form.id}`;
				this.form._method = 'PUT';
			} else {
				_endpoint = `/users`;
			}
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.successNotification(data.message);
					this.setUser();
					this.reset();
					// this.$router.go(0);
				}
			} catch (error) {
				this.load = false;
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error);
				}
			}
		},
		async setUser() {
			let id = this.form.id;
			let _endpoint = `/users/${id}`;
			let { data } = await request.get(_endpoint);			
			if (data) {
				localStorage.setItem('user', JSON.stringify(data));
				this.$store.commit('auth/setUser', data);
			}
			return true;
		},
		async get_id(id) {
			this.form.id = id;
			let _endpoint = `/users/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					let form = this.form;
					Object.keys(form).forEach(key => {
						this.form[key] = data[key];
					});
					this.form.contact_id = data.contact
					this.form.user_id = data.user
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		reset() {
			this.load = false;
			this.errors = {};
			this.form.id = '';
			this.form.name = '';
			this.form.email = '';
			this.form.profiles_id = '';
			this.form._method = 'POST';
			this.$modal.hide('Profileusers');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style scoped>
.pad {
	padding: 10px !important;
}

.mx-datepicker {
	width: 100%;
}

.btn-pass {
	float: right;
	margin-top: -34px;
	margin-right: 4px;
	border-radius: 20px;
}

.input-pass {
	padding-right: 46px;
}</style>
