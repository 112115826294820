<template>
    <div class="col-12">
        <h2> Dinámicas</h2>
        <div class="row mt-4">
            <div class="col-md-4" v-for="typeD in catalogue.dynamic_types" :key="typeD.id">
                <router-link :to="{ path: 'dynamics-list/'+typeD.id }" class="btn btn-outline-secondary btn-lg btn-block text-center pt-5 pb-5 mb-3">
                    <h3>{{ typeD.name }}</h3>
                    <i v-if="typeD.id == 1" class="fa-solid fa-keyboard fa-4x"></i>
                    <i v-if="typeD.id == 2" class="fa-solid fa-chalkboard-user fa-4x"></i>
                    <i v-if="typeD.id == 3" class="fa-brands fa-cloudscale fa-4x"></i>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Catalogue from '../../../services/catalogue';
export default {
    data() {
        return {
            catalogue: new Catalogue(),
        }
    },
    mounted() {
        this.catalogue.get_dynamic_types();
    },
}
</script>