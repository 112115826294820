import axios from 'axios'

let BASE = '';
let urlBase = window.location.hostname;
if (urlBase == 'localhost') {
    BASE = 'http://127.0.0.1:8000/api';
}

if (urlBase == 'staging-conectando-lideres.sorianoariza.com') {
    BASE = 'https://api-staging-conectando-lideres.sorianoariza.com/api';
}

if (urlBase == 'sraz.mx') {
    BASE = 'https://api-conectando-lideres.sraz.mx/api';
}

if (urlBase == 'dev.sraz.mx' || urlBase == 'www.dev.sraz.mx') {
    BASE = 'https://api-dev.sraz.mx/api';
}

export default {
    async create(payload) {
        let endpoint = `${BASE}/logbooks`
        return await axios.post(endpoint, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    downloadPdf(id) {
        window.open(`${BASE}/logbooks/pdf/${id}`)
    },
    
    downLoadReportQuestion(id){
        window.open(`${BASE}/respose_pdf/${id}`)
    }
}