<script>
export default {
    props: {
        taggings: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            taggingsChecks:[],
            dataTaggins:[]
        }
    },
    methods: {

    },
    mounted() {
        let result = JSON.parse(this.taggings);
        if(result){
            this.dataTaggins = result[0];        
        }
    }
}
</script>
<template>
    <div class="row justify-content-center">
        <div class="col-md-10 ">
            <div class="card" v-for="(tagging, index_tagging) in dataTaggins.topics" :key="index_tagging">
                <div class="card-body text-left">
                    <input type="checkbox" style="left: 45px;" class="form-check-input" v-model="taggingsChecks" :value="tagging.code"/>
                    <span class="ml-5">{{ tagging.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>