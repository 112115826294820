<template>
    <modal name="NewParticipants" v-bind:click-to-close="false" :width="400" :adaptive="true">
        <div class="row p-4" v-if="load == false">
            <div class="col-12">
                <h4>{{ title }}</h4>
                <hr>
                <div class="form-group mb-2">
                    <label for="">Nombre(s) :</label>
                    <input v-model="form.name" class="form-control">
                    <div v-if="(errors && errors.name)" class="text-danger ml-3">{{ errors.name[0] }}</div>
                </div>
                <div class="form-group mb-2">
                    <label for="">Apellido(s) :</label>
                    <input v-model="form.last_name" class="form-control">
                    <div v-if="(errors && errors.last_name)" class="text-danger ml-3">{{ errors.last_name[0] }}</div>
                </div>
                <div class="form-group mb-2">
                    <label for="">Número empleado :</label>
                    <input v-model="form.code" class="form-control">
                    <div v-if="(errors && errors.code)" class="text-danger ml-3">{{ errors.code[0] }}</div>
                </div>
                <div class="form-group mb-2">
                    <label for="">Email :</label>
                    <input v-model="form.email" class="form-control">
                    <div v-if="(errors && errors.email)" class="text-danger ml-3">{{ errors.email[0] }}</div>
                </div>
                <div class="form-group mb-2">
                    <label for="">Teléfono :</label>
                    <input v-model="form.phone" class="form-control">
                    <div v-if="(errors && errors.phone)" class="text-danger ml-3">{{ errors.phone[0] }}</div>
                </div>
                <div class="form-group mb-2">
                    <label for="">Territorio :</label>                    
                    <select v-model="form.territory_id" class="form-control">
                        <option v-for="territory in catalogue.territories" :key="territory.id" :value="territory.id">{{ territory.name }}</option>
                    </select>
                    <div v-if="(errors && errors.territory_id)" class="text-danger ml-3">{{ errors.territory_id[0] }}</div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-outline-warning" @click="reset">
                            <i class="fas fa-undo-alt"></i>
                            Cerrar
                        </button>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-outline-success" @click="save">
                            <i class="fas fa-save"></i>
                            Guardar
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-12 text-center p-4">
                <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                <h5 class="mt-3">Enviando...</h5>
            </div>
        </div>
    </modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'
import Catalogue from '@/services/catalogue';

export default {
    name: "new-participants",
    data() {
        return {
            title: 'Crear Participante',
            form: {
                id: '',
                name: '',
                last_name: '',
                code: '',
                email: '',
                phone: '',
                territory_id: '',
				_method : 'POST'
            },
            errors: {},
            load: false,
            catalogue: new Catalogue(),
        }
    },
    created() {
        Event.$on("event-new-participants", id => {
            this.title = 'Nuevo Participante';
            if (id) {
                this.title = 'Editar Participante';
                this.get_id(id);
            }
        });
    },
    mounted() {
        this.catalogue.get_territories();       
    },
    methods: {
        async save() {
            this.load = true;
            let _endpoint = '';
            if (this.form.id) {
                _endpoint = `/participants/${this.form.id}`;
                this.form._method = 'PUT';
            } else {
                _endpoint = `/participants`;
            }
            try {
                let { data } = await request.post(_endpoint, this.form)
                if (data) {
                    this.reset();
                    this.successNotification(data.message);
                    Event.$emit('event-load-participants', '');
                }
            } catch (error) {
                this.load = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        async get_id(id) {
            this.form.id = id;
            let _endpoint = `/participants/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    let form = this.form;
                    Object.keys(form).forEach(key => {
                        this.form[key] = data[key];
                    });
                    this.form.contact_id = data.contact
                    this.form.participant_id = data.participant
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        reset() {
            this.load = false;
            this.errors = {};
            this.form.id = '';
            this.form.name = '';
            this.form.last_name = '';
            this.form.code = '';
            this.form.email = '';
            this.form.phone = '';
			this.form._method = 'POST';
            this.$modal.hide('NewParticipants');
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>
<style>
.pad {
    padding: 10px !important;
}

.mx-datepicker {
    width: 100%;
}
</style>
