<template>
	<div class="col-12">
		<H2>Próximos eventos</H2>
		<div class="row">
			<div class="col-md-4">
				<input type="text" @keyup="list()" placeholder="Buscar" v-model="search.name" class="form-control">
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-12 p-5 text-center" v-if="load_elemnt">
				<i class="fa-solid fa-spinner fa-spin-pulse fa-5x text-danger"></i>
			</div>
			<div class="col-12" v-else>
				<div class="row text-center p-5" v-if="events.data.length == 0">
					<h2>Sin resultados</h2>
				</div>
				<div class="row" v-else>
					<div class="col-md-6 col-sm-6 col-12" v-for="(event, index) in events.data" :key="index">
						<div class="info-box p-0 shadow" @click="initEvent(event.id)" style="background: #f2f2f2;">
							<span class="info-box-icon bg-light b-logo ">
								<img v-if="event.event_types_id == 3" src="@/assets/img/chat.png" class="p-4" alt="Logo"
									width="110px;">
								<img v-else src="@/assets/img/conectando.png" class="p-4" alt="Logo" width="110px;"><br>
							</span>
							<div class="info-box-content">
								<div class="p-2 mb-4">
									<span class="info-box-text">
										<h2 v-if="event.event_types_id != 3">Conectando entre</h2>
										<h2 v-else>&nbsp;</h2>
									</span>
									<span class="info-box-text mt-n2">
										<h3 v-if="event.event_types_id == 1"><span class="text-danger">líderes</span></h3>
										<h3 v-if="event.event_types_id == 2"><span class="text-danger">líderes</span> con la
											<span class="text-danger">DG</span></h3>
										<h3 v-if="event.event_types_id == 3"><span class="text-danger">CHarlando</span></h3>
										<p class="mt-n2 mb-1" >
											<span class="mr-2">Moderador:</span>
											<strong v-if="event.moderator">{{ event.moderator.name }}</strong>
											<strong v-else>Sin moderador</strong>
										</p>
									</span>
								</div>

								<div class="bg-secondary mt-2 dt_slider">
									{{ event.date | dateName }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<router-link :to="{ name: 'form_events' }" class="btn btn_add rounded-circle btn-danger">
			<i class="fas fa-plus"></i>
		</router-link>
	</div>
</template>

<script>
import request from '../../services/request'
import moment from 'moment';
import '../../helpers/filters'
import { mapActions } from 'vuex';

export default {

	data() {
		return {
			load_elemnt: true,
			search: {
				name: '',
				_init: moment().format('YYYY-MM-DD')
			},
			events: [{
				data: []
			}]
		}
	},
	methods: {
		async list(page = 1) {
			this.load_elemnt = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/events';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					this.events = data;
					this.load_elemnt = false;
				}
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
        initEvent(id) {           
            window.open(`../#/moderator/${id}`);
        },
		...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
	},
	mounted() {
		this.list();
	}
}
</script>

<style scoped>
.btn_add {
	bottom: 20px;
	position: fixed;
	right: 20px;
}

.bg-secondary {
	background-color: #98989a;
}

.pagination {
	margin-bottom: 0;
}

.b-logo {
	border-radius: 5px 55px 55px 5px;
	width: 120px;
	z-index: 1;
}

.dt_slider::before {
	content: "";
	background-color: #6c757d;
	height: 29px;
	position: absolute;
	width: 100px;
	bottom: 0px;
	left: -100px;
}

.dt_slider {
	bottom: 0px;
	position: absolute;
	width: calc(100% - 130px);
	border-radius: 0px 0px 5px 0px;
}

.shadow {
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
</style>
