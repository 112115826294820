<script>
import WildcardPresenter from '@/components/Wildcards/WildcardPresenterComponent.vue';
export default {
    components :{
        WildcardPresenter
    },
    data() {
        return {
            localData: {
                question_type: 'Reto',
                question: 'asdasdasdasdasdasd asd asd hajklsd hjklashd kjashd jklashd askld hjlkasjd lkjaskldj lkasjdlk jasjddlk jlasdkjl khaskjd'
            }
        }
    }
}
</script>
<template>
    <div>
        <div class="box-size">
            <tab-component>
                <wildcard-presenter :data="localData" count="3"></wildcard-presenter>
            </tab-component>
        </div>
    </div>
</template>

<style scoped>
.box-size{
    width: 600px;
    position: absolute;

}
</style>