import axios from "axios";

// const URL = `http://conectando_lideres_back.dv/api`;
let URL = '';
let urlBase = window.location.hostname;
if (urlBase == 'localhost') {
    URL = 'http://127.0.0.1:8000/api';
}

if (urlBase == 'staging-conectando-lideres.sorianoariza.com') {
    URL = 'https://api-staging-conectando-lideres.sorianoariza.com/api';
}

if (urlBase == 'sraz.mx') {
    URL = 'https://api-conectando-lideres.sraz.mx/api';
}

if (urlBase == 'dev.sraz.mx' || urlBase == 'www.dev.sraz.mx') {
    URL = 'https://api-dev.sraz.mx/api';
}

export default {
    list(endpoint = null, page = 1, search = null) {
        let showPages = 50;
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0] == '/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.get(final_endpoint, { params: { page: page, _search: search, n: showPages } })
        } else {
            return;
        }
    },
    get(endpoint = null, params = {}) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0] == '/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.get(final_endpoint, { params })
        } else {
            return;
        }
    },
    post(endpoint = null, payload) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0] == '/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.post(final_endpoint, payload);
        } else {
            return;
        }
    },
    postMedia(endpoint = null, payload) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0] == '/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }

            return axios.post(final_endpoint, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } else {
            return;
        }
    },
    destroy(endpoint) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0] == '/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.delete(final_endpoint);
        } else {
            return;
        }
    },
    logout() {
        return axios.post('/logout');
    },
}
