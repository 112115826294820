<template>
    <div class="col-12 mt-3">
        <ParticipantsForm/>
		<ParticipantsImport
			:modal_id="modals.participant_import"
			
			
		/>
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center w-full">
				<div class="flex w-100">
					<h3 class="card-title">Lista de participantes</h3>
				</div>
				<div class="flex w-100">
					<input type="text" v-model="search" placeholder="Buscar participantes" class="form-control">
				</div>
				<div class="d-flex justify-content-end w-100" style="gap: 10px;">
					<button class="btn btn-sm btn-success float-right" @click="showModalUser('')">
						<i class="fa fa-plus pr-1"></i> Agregar
					</button>
					<button class="btn btn-sm btn-success float-right" @click="showModal(modals.participant_import)">
						<i class="fa fa-upload pr-1"></i> Importar
					</button>
				</div>
            </div>
            <div class="card-body position-relative">
				<div class="box_loading" v-show="loadingData"></div>
                <div class="table-responsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Núm. Empleado</th>
                                <th>Teléfono</th>
                                <th>Territorio</th>
                                <th>En eventos</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody v-if="models">
							<tr v-for="(participant, index) in models" :key="index">
								<td>{{ (pagination && pagination.hasOwnProperty('from')) ? (pagination.from + index) : null }}</td>
								<td>{{ participant.name }} {{ participant.last_name }}</td>								
								<td>{{ participant.email }}</td>								
								<td>{{ participant.code }}</td>								
								<td>{{ participant.phone }}</td>								
								<td>
									<span v-if="participant.territory">
										{{ participant.territory.name }}
									</span>
									<span v-else>
										Sin territorio
									</span>
								</td>								
								<td>
									<h4>
										<span class="badge bg-secondary">{{ participant.events.length }}</span>
									</h4>
								</td>								
								<td>
									<button @click=showModalUser(participant.id) class="m-1 btn btn-primary btn-sm">
										<i class="fas fa-edit "> </i>
									</button>
									<button type="button" @click="deleted(participant.id)" class="m-1 btn-danger btn btn-sm ">
										<i class="fa fa-trash"></i>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
                    </table>
					<div class="d-flex justify-content-between align-items-top">
						<div class="pagination-information">
							<div v-if="legendPaginate" v-html="legendPaginate"></div>
						</div>
						<div class="pagination">
							<CustomPagination :pagination="pagination" :limit="7" v-on:change-page="fecthData" />
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomPagination from '@/components/CustomPagination.vue';
import ParticipantsForm from '@/components/Participants/ParticipantsFormComponent.vue';
import ParticipantsImport from '@/components/Participants/ParticipantsImportComponent.vue';

import request from '../../services/request'

var _ = require('lodash');

export default{
    components: {
		CustomPagination,
		ParticipantsForm,
		ParticipantsImport
	},
    data(){
        return {
            participants:[],
			modals: {
				participant_import: 'ParticipantsImport',
			},
			// ----------
			models: [],
			pagination: {},
			search: null,
			loadingData: true,

        }
    },
	watch: {
        search: {
            handler() {
                this.debounceSearch();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
		// eslint-disable-next-line
		debounceSearch: _.debounce(function () {
			console.log("buscar");
            this.fecthData();
        }, 400),
        async fecthData(page=1, time=1) {
            let vm = this;
            let service  = request;
            vm.loadingData = true;
            try {
                // debugger
				let _endpoint = '/participants';
                let {data} = await service.list(_endpoint, page, vm.search)
                vm.models = data.data;
                vm.pagination = data;
            } catch (error) {
                console.error(error)
                if (time<3) {
                    setTimeout( async () => {
                        await vm.fecthData(page=1, time+1);
                    }, 400);
                }
            }
            vm.loadingData = false;
        },

        showModalUser(id) {
			Event.$emit('event-new-participants', id);
			this.$modal.show('NewParticipants');
		},
		showModal(_modal) {
			let vm = this;
			setTimeout(() => {
				vm.$modal.show(_modal);
			}, 100);
		},
        deleted: function (id) {
			let tit = 'Eliminar participante';
			let txt = 'Seguro que quiere eliminar al participante';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/participants/' + id;
			let vm = this;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				vm.fecthData();
				vm.successNotification(response.data.message);			
			} catch (error) {
				vm.errors = await this.errorNotification(error)
			}
		},
		...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
	computed: {
        legendPaginate() {
            let vm = this;
			// eslint-disable-next-line
            if (vm.pagination && vm.pagination.hasOwnProperty('from') && vm.pagination.hasOwnProperty('to') && vm.pagination.hasOwnProperty('total') &&  vm.pagination.total > 0) {
                return `Mostrando del <strong>${vm.pagination.from}</strong> al <strong>${vm.pagination.to}</strong> de <strong>${vm.pagination.total}</strong> filas`;
            } else {
                return null;
            }
        },
        pagination_current_page() {
            let vm = this;
			// eslint-disable-next-line
            return ( vm.pagination && vm.pagination.hasOwnProperty('current_page') && vm.pagination.current_page) ? vm.pagination.current_page : null;
        },
	},
    mounted() {
        this.fecthData();
	},
    created() {
		Event.$on("event-load-participants", () => {
            this.fecthData();
		});
	},
}
</script>

<style scoped>
.box_loading {
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.8);
	justify-content: center;
	align-items: center;
	text-align: center;
}
.pagination {
    margin-bottom: 0;
}
</style>
