<script>
import moment from 'moment'
export default {
    props: {
        prop_time: {
            type: String,
            default: '02:00'
        },
        prop_start: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            time_left: null,
            intervalId: null,
            minute: null,
            second: null,
            time: '00:00'
        }
    },
    computed: {
        minutes() {
            return Math.floor(this.time_left / 60).toString().padStart(2, '0');
        },
        seconds() {
            return (this.time_left % 60).toString().padStart(2, '0');
        },
    },
    created() {
        this.time = this.prop_time;
        this.convertTimer();
    },
    /* mounted() {
        this.convertTimer();
        this.startTimer();
        console.log(this.totalTime);
    }, */
    methods: {
        startTimer() {
            this.intervalId = setInterval(() => {
                if (this.time_left > 0) {
                    this.time_left--;
                } else {
                    this.stopTimer();
                    this.$emit('finish');
                }
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.intervalId);
        },
        convertTimer() {
            const timers = this.time.split(':');
            this.minute = parseInt(timers[0]);
            this.second = parseInt(timers[1]);
            this.time_left = moment.duration({
                minutes: this.minute,
                seconds: this.second
            }).asSeconds();
        }
    },
    watch: {
        prop_start: function (newVal) {
            if(newVal == true){
                this.startTimer();
            }else{
                this.stopTimer();
            }
        }
    }
}
</script>
<template>
    <div>
        {{ minutes }} : {{ seconds }}</div>
</template>